import { useEffect, useState } from 'react'

function retryGTM(query, timout) {
  try {
    query()
  } catch (err) {
    setTimeout(() => retryGTM(query, timout), timout)
  }
}

function retryLearnQ(query, timout) {
  try {
    query()
  } catch (err) {
    setTimeout(() => retryLearnQ(query, timout), timout)
  }
}

const useTracking = () => {
  const [itemData, setItemData] = useState()
  useEffect(() => {
    if (itemData) {
      const { title, id, price, url, sku, imageURL } = itemData
      const item = {
        ProductName: title,
        ProductID: id,
        Categories: ['Luggage'],
        URL: url,
        Brand: 'July',
        Price: price,
        CompareAtPrice: price,
        ImageURL: imageURL,
      }
      setTimeout(() => {
        var _learnq = window._learnq || []

        retryLearnQ(() => {
          _learnq.push(['track', 'Viewed Product', item])
        }, 3000)

        retryLearnQ(() => {
          _learnq.push([
            'trackViewedItem',
            {
              Title: item.ProductName,
              ItemId: item.ProductID,
              Categories: item.Categories,
              ImageUrl: item.ImageURL,
              Url: item.URL,
              Metadata: {
                Brand: item.Brand,
                Price: item.Price,
                CompareAtPrice: item.CompareAtPrice,
              },
            },
          ])
        }, 3000)

        // if (window.gtag) {
        //   window.gtag('event', 'view_item', {
        //     "items": [
        //       {
        //         "id": item.ProductID,
        //         "name": item.ProductName,
        //         "brand": "July",
        //         "variant": itemData.variant,
        //         "price": item.Price
        //       }
        //     ]
        //   });
        // }
        retryGTM(() => {
          window.dataLayer.push({
            event: 'ViewContent',
            ecommerce: {
              currencyCode: 'AUD',
              detail: {
                products: [
                  {
                    ...itemData,
                  },
                ],
              },
            },
          })
        }, 3000)
      }, 500)
    }
  }, [itemData])
  const addToCart = () => {
    const { title, id, price, url, sku, imageURL } = itemData
    const item = {
      ProductName: title,
      ProductID: id,
      Categories: ['Luggage'],
      URL: url,
      Brand: 'July',
      Price: price,
      CompareAtPrice: price,
      ImageURL: imageURL,
    }
    var _learnq = window._learnq || []

    retryLearnQ(() => {
      _learnq.push([
        'track',
        'Added to Cart',
        {
          $value: item.Price,
          AddedItemProductName: item.ProductName,
          AddedItemProductID: item.ProductID,
          AddedItemSKU: sku,
          AddedItemCategories: item.Categories,
          AddedItemURL: url,
          AddedItemPrice: item.Price,
          AddedItemQuantity: 1,
          AddedItemImageURL: item.ImageURL,
          CheckoutURL: 'http://checkout.july.com/checkout',
          Items: [
            {
              ProductID: item.ProductID,
              SKU: sku,
              ProductName: item.ProductName,
              Quantity: 1,
              ItemPrice: item.Price,
              RowTotal: item.Price,
              ProductURL: url,
              ProductCategories: item.Categories,
              ImageUrl: item.ImageURL,
            },
          ],
        },
      ])
    }, 3000)
    // if (window.gtag) {
    //   console.log('add to cart gtag')
    //   window.gtag('event', 'add_to_cart', {
    //     "items": [
    //       {
    //         "id": item.ProductID,
    //         "name": item.ProductName,
    //         "brand": "July",
    //         "variant": itemData.variant,
    //         "price": item.Price
    //       }
    //     ]
    //   });
    // }

    retryGTM(() => {
      window.dataLayer.push({
        event: 'addToCart',
        ecommerce: {
          currencyCode: 'AUD',
          add: {
            // 'add' actionFieldObject measures.
            products: [
              {
                //  adding a product to a shopping cart.
                ...itemData,
              },
            ],
          },
        },
      })
    }, 3000)
  }
  return [addToCart, setItemData]
}

export default useTracking
