import React, { useContext, useEffect, useMemo, useState } from 'react'

import locales from '../constants/locales'
import StoreContext from '../context/StoreContext'

function useProductData(data, personalised) {
  const context = useContext(StoreContext)
  const locale = locales[context.language === 'root' ? 'au' : context.language]
  const findLocale = (locale) => {
    let localeData = data['edges'].find(
      (el) => el['node']['lang'] === locale.content
    )
    localeData = localeData?.node
    return localeData
  }
  const localeData = useMemo(() => findLocale(locale), [locale])

  const [title, setTitle] = useState(localeData.title?.value)
  const [price, setPrice] = useState(
    personalised
      ? localeData.price?.value
      : localeData.free_personalisation?.value
      ? localeData.price?.value
      : localeData.price_personalised?.value
  ) //
  const [personalisedPrice, setPersonalisedPrice] = useState(
    localeData.price_personalised?.value
  )
  const [hasPersonalisation, setHasPersonalisation] = useState(
    localeData.has_personalisation?.value
  )
  const [freePersonalisation, setFreePersonalisation] = useState(
    localeData.free_personalisation?.value
  ) //

  const [discountPrice, setDiscountPrice] = useState(
    personalised
      ? localeData.discount_price?.value
      : localeData.free_personalisation?.value
      ? localeData.discount_price?.value
      : localeData.personalised_discount_price?.value
  ) //
  const [personalisationPrice, setPersonalisationPrice] = useState(
    localeData.personalisation_price?.value
  )

  const [description, setDescription] = useState(localeData.description?.value)
  const [overview, setOverview] = useState(localeData.overview?.value)
  const [specs, setSpecs] = useState(localeData.specs?.value)
  const [reviewId, setReviewId] = useState(localeData.review_id?.value)
  const [shopifyIds, setShopifyIds] = useState(
    localeData.shopify_ids?.value?.data &&
      localeData.shopify_ids?.value?.data[
        personalised || localeData.has_personalisation?.value === false
          ? 'default'
          : 'personalised'
      ]
  )
  const [fbIDs, setFbIDs] = useState(localeData.fb_ids?.value?.data)
  const [oosData, setOOSData] = useState(
    localeData.oosDetails?.value?.data.map((line) => {
      const oosData = context.skuOOS(line.sku)
      return {
        ...line,
        ...(oosData
          ? {
              on_site: oosData.on_site,
              avaliable: oosData.avaliable,
              show_personalistion: oosData.personalisation,
              oos_message: oosData.oos_message,
              preorder: oosData.preorder,
            }
          : {}),
      }
    })
  )

  useEffect(() => {
    setPrice(
      personalised
        ? localeData.price?.value
        : localeData.free_personalisation?.value
        ? localeData.price?.value
        : localeData.price_personalised?.value
    ) //
    const allShopifyIds = localeData.shopify_ids?.value?.data
    setShopifyIds(
      allShopifyIds &&
        allShopifyIds[
          personalised || localeData.has_personalisation?.value === false
            ? 'default'
            : 'personalised'
        ]
    )
    setDiscountPrice(
      personalised
        ? localeData.discount_price?.value
        : localeData.free_personalisation?.value
        ? localeData.discount_price?.value
        : localeData.personalised_discount_price?.value
    ) //
  }, [personalised])

  useEffect(() => {
    setTitle(localeData.title?.value)
    setPrice(
      personalised
        ? localeData.price?.value
        : localeData.free_personalisation?.value
        ? localeData.price?.value
        : localeData.price_personalised?.value
    ) //
    setPersonalisedPrice(localeData.price_personalised?.value)
    setHasPersonalisation(localeData.has_personalisation?.value)
    setFreePersonalisation(localeData.free_personalisation?.value) //
    setDescription(localeData.description?.value)
    setOverview(localeData.overview?.value)
    setSpecs(localeData.specs?.value)
    setReviewId(localeData.review_id?.value)
    setFbIDs(localeData.fb_ids?.value?.data)
    setOOSData(
      localeData.oosDetails?.value?.data.map((line) => {
        const oosData = context.skuOOS(line.sku)
        return {
          ...line,
          ...(oosData
            ? {
                on_site: oosData.on_site,
                avaliable: oosData.avaliable,
                show_personalistion: oosData.personalisation,
                oos_message: oosData.oos_message,
                preorder: oosData.preorder,
                ...(!oosData.avaliable
                  ? {
                      signup_id:
                        context.language === 'au' ? 'VVPPd7' : 'TjtnfB',
                    }
                  : {}),
              }
            : {}),
        }
      })
    )
    setPersonalisationPrice(localeData.personalisation_price?.value)
  }, [data, context.language, context.oosData])

  return {
    title,
    price,
    personalisedPrice,
    hasPersonalisation,
    freePersonalisation,
    description,
    overview,
    specs,
    reviewId,
    shopifyIds,
    fbIDs,
    discountPrice,
    personalisationPrice,
    oosData,
  }
}

export default useProductData
